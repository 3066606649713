<template>
  <div class="cont-whole-box">
    <div style="padding: 30px;">
      <div class="margno-t">
        <div class="table-er-box">
          <!--TODO:按钮-->
          <el-button plain @click="addItem">新增</el-button>
          <el-button plain @click="answerObj.visible = true"
            >设置答案模板</el-button
          >
        </div>
        <!--TODO:内容-->
        <Table
          :tableData="
            dataTable.slice(
              (pagerInfo.pageIndex - 1) * pagerInfo.pageSize,
              (pagerInfo.pageIndex - 1) * pagerInfo.pageSize +
                pagerInfo.pageSize
            )
          "
          @editItem="editItem"
          @delItem="getData"
          @updateItem="updateItem"
          :user="user"
        />
        <div class="pagin-box">
          <div class="pagin-wz">
            <!--分页-->
            <Pager :pagerInfo="pagerInfo" @changeIndex="changeIndex" />
          </div>
        </div>
      </div>
    </div>
    <From
      :visible="visible"
      :user="user"
      :dimensions="dimensions"
      :LX="LX"
      :data="formData"
      :title="dialogTitle"
      @closeDialog="closeDialog"
      @updateItem="updateItem"
    />
    <Answer :visible.sync="answerObj.visible" />
  </div>
</template>

<script>
import From from "./Form.vue";
import Table from "./Table.vue";
import Pager from "@/components/Pager.vue";
import Answer from "./answer.vue";
export default {
  components: {
    Pager,
    Table,
    From,
    Answer,
  },
  data() {
    return {
      pagerInfo: {
        pageIndex: 0,
        pageSize: 9,
        totalCount: 0,
      },
      token: "",
      user: {},
      schoolId: "",
      visible: false,
      dataTable: [],
      formData: {},
      newformData: {
        Id: "",
        KSSJ: new Date(),
        JSSJ: new Date(),
        WJMC: "",
        SSWD: "",
        CYXK: false,
        CourseIds: [],
        SSXX: "",
        CJR: "",
        CJSJ: new Date(),
        SYZT: 1,
        SFSC: 2,
        SSWDStr: "",
        SYZTStr: "",
        WJTMBList: [
          {
            Id: "",
            WJId: "",
            PXH: 1,
            TMMC: "",
            SSLB: 1,
            XZLX: 1,
            JDXX: 0,
            WJFXBJson: "0",
            WJFXBList: [
              {
                Id: "",
                WJId: "",
                PXH: 1,
                TMId: "",
                TMMC: "",
                DF: 0,
                TB: "",
                SFSC: 2,
                FXLX: 1,
              },
            ],
          },
        ],
      },
      dialogTitle: "新增问卷",
      dimensions: [],
      answerObj: {
        visible: false,
      },
      LX: 0,
    };
  },
  created: function() {
    this.token = this.$store.state.token;
    this.user = JSON.parse(localStorage.getItem("userinfo"));
    if (this.user) {
      if (this.user.SSZZId) {
        this.schoolId = this.user.SSZZId;
      }
    }
    this.resetData();
    this.getData();
  },
  methods: {
    resetData() {
      // 根据不同路由地址，返回不同数据
      if (this.$route.fullPath === "/questionnaire1") {
        this.LX = 1;
      } else if (this.$route.fullPath === "/questionnaire2") {
        this.LX = 2;
      }
    },
    changeIndex(val) {
      this.pagerInfo.pageIndex = val;
    },
    closeDialog(data) {
      if (data) {
        this.getData();
      }
      this.visible = false;
    },
    addItem() {
      this.dialogTitle = "新增问卷";
      this.formData = this.newformData;
      this.visible = true;
    },
    editItem(data) {
      this.dialogTitle = "编辑问卷";
      this.formData = data;
      this.visible = true;
    },
    async getData() {
      await this.getDimensions();
      this.$api.post(
        "/v1/questionnaire/GetQuestionnaireList",
        {
          token: this.token,
          SchoolId: this.schoolId,
          LX: this.LX,
        },
        (r) => {
          if (r.Code === 0) {
            let arr = r.Target || [];

            arr.forEach((item) => {
              try {
                this.dimensions.forEach((dimension) => {
                  if (dimension.value === item.SSWD) {
                    item.SSWDStr = dimension.label;
                    throw new Error("找到维度");
                  } else {
                    if (dimension.children) {
                      dimension.children.forEach((child) => {
                        item.SSWDStr = child.label;
                        throw new Error("找到维度");
                      });
                    }
                  }
                });
              } catch (err) {
                //
              }
              if (item.WJTMBList) {
                item.WJTMBList.forEach((it) => {
                  if (it.WJFXBList && it.WJFXBList.every((i) => i.TMQZ)) {
                    it.WJFXBList.sort((a, b) => a.TMQZ.localeCompare(b.TMQZ));
                  }
                });
              }
            });
            this.dataTable = arr;
            this.pagerInfo.totalCount = this.dataTable.length;
            this.isLoading = false;
          }
        }
      );
      this.pagerInfo.totalCount = this.dataTable.length;
    },
    getDimensions() {
      const pageinfo = {
        PageIndex: 0,
        PageSize: parseInt(Number.MAX_SAFE_INTEGER / 10000000),
      };
      return new Promise((resolve, reject) => {
        this.$api.post(
          "/v1/dimension/GetDimensionTree",
          {
            token: this.token,
            PagerInfo: pageinfo,
            SchoolId: this.schoolId,
            LX: this.LX,
          },
          (r) => {
            this.dimensions = JSON.parse(JSON.stringify(r.Target));
            resolve();
          }
        );
      });
    },
    updateItem(data) {
      this.dataTable.splice(
        this.dataTable.findIndex((c) => c.Id === data.Id),
        1,
        data
      );
    },
    //此函数暂时不需要
    guid: function() {
      return (
        this.guidContent() +
        this.guidContent() +
        "-" +
        this.guidContent() +
        "-" +
        this.guidContent() +
        "-" +
        this.guidContent() +
        "-" +
        this.guidContent() +
        this.guidContent() +
        this.guidContent()
      );
    },
    guidContent: function() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },
  },
  watch: {
    $route: {
      handler: "resetData",
    },
  },
};
</script>
